import React from 'react'
import { RouteProps } from 'react-router-dom'

import Cookies from '../cookies/Container'
import FitnessHero from '../fitness-hero/Container'
import SEO from '../seo'

const FitnessContainer: React.FC<RouteProps<'/fitness'>> = () => {
  return (
    <>
      <SEO title="Fitness" />
      <FitnessHero />
      <Cookies />
    </>
  )
}

export default FitnessContainer
