import React from 'react'

import AvocaderiaText from '../../../static/images/fitness-classes/avocaderia-text.png'
import Avocaderia from '../../../static/images/fitness-classes/avocaderia.png'
import BeatnicText from '../../../static/images/fitness-classes/beatnic-text.png'
import Beatnic from '../../../static/images/fitness-classes/beatnic.png'
import FieldtripText from '../../../static/images/fitness-classes/field-trip-text.png'
import Fieldtrip from '../../../static/images/fitness-classes/field-trip.png'
import CopowerYoga from '../../../static/images/fitness-classes/full/corepower-yoga.png'
import F45 from '../../../static/images/fitness-classes/full/f45.png'
import HealthyMeals from '../../../static/images/fitness-classes/full/healthy-meals.png'
import SoulCycle from '../../../static/images/fitness-classes/full/soul-cycle.png'
import JuicePressText from '../../../static/images/fitness-classes/juice-press-text.png'
import JuicePress from '../../../static/images/fitness-classes/juice-press.png'
import LogosFull from '../../../static/images/fitness-classes/logos-full.png'
import LogosHalf from '../../../static/images/fitness-classes/logos-half.png'
import LogosMobile from '../../../static/images/fitness-classes/logos-mobile.png'
import SweetgreenText from '../../../static/images/fitness-classes/sweetgreen-text.png'
import Sweetgreen from '../../../static/images/fitness-classes/sweetgreen.png'
import TaimText from '../../../static/images/fitness-classes/taim-text.png'
import Taim from '../../../static/images/fitness-classes/taim.png'
import useHomepage from '../../hooks/useHomepage'
import UtmContent from '../../models/UtmContent'
import { UrlService } from '../../services'
import HeroForm from './HeroForm'
import './hero.less'

const FitnessHero: React.FC = () => {
  const { activeCityName } = useHomepage()

  const utmContent = new UtmContent({ position: 'fitness' }).toString()

  return (
    <>
      <div className="fitness-container">
        <div className="fitness-hero">
          <div className="images-mobile mobile-content"></div>
          <div className="images-left desktop-content"></div>
          <div className="join-fitness-classes">
            <div className="introducing">SAVE BIG ON:</div>
            <div className="hero-title">
              <div className="free-fitness-class">
                Fitness Classes&nbsp;
                <span className="and-6-lunch">at</span>
              </div>
              <div className="and-6-lunch">
                your&nbsp;
                <span className="free-fitness-class">Favorite Studios</span>
              </div>
              <div className="and-6-lunch">
                &&nbsp;
                <span className="teal-brush">Lunch</span>
                &nbsp;from your
              </div>
              <div className="teal-brush-bigger">Favorite Restaurants!</div>
            </div>
            <div className="form-container">
              <HeroForm />
            </div>
          </div>
          <div className="images-right desktop-content"></div>
        </div>
        <div className="logos">
          <div className="logos-full">
            <img src={LogosFull} />
          </div>
          <div className="logos-half">
            <img src={LogosHalf} />
          </div>
          <div className="logos-mobile">
            <img src={LogosMobile} />
          </div>
        </div>
        <div className="offers">
          <div className="title">
            Workouts you <span className="underline-brush">love,</span>
            <br />
            fueled by the meals you{' '}
            <span className="need">
              need.<div className="circle-brush"></div>
            </span>
          </div>
          <div className="grid-container">
            <div className="grid-item grid-item-image">
              <div>
                <img src={SoulCycle} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div className="class-name">SoulCycle</div>
              <div className="meal-after">
                followed by the <b>Harvest Bowl from Sweetgreen!</b>
              </div>
              <div className="enjoy-text">Enjoy a spin class + a high protein meal</div>
              <a className="red-cta" href={UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })}>
                JOIN MEALPAL TODAY
              </a>
            </div>
          </div>

          <div className="grid-container flip-order">
            <div className="grid-item grid-item-image">
              <div>
                <img src={F45} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '367px' }}>
              <div className="class-name">F45</div>
              <div className="meal-after">
                followed by the <b>Kale Caesar from Beatnic!</b>
              </div>
              <div className="enjoy-text">Enjoy a HIIT class + a low calorie meal</div>
              <a className="red-cta" href={UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })}>
                JOIN MEALPAL TODAY
              </a>
            </div>
          </div>
          <div className="grid-container">
            <div className="grid-item grid-item-image">
              <div>
                <img src={CopowerYoga} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '493px' }}>
              <div className="class-name">Corepower Yoga</div>
              <div className="meal-after">
                followed by the <b>Almond Berry Blast from Just Salad!</b>
              </div>
              <div className="enjoy-text">Enjoy a yoga class + a refreshing protein smoothie</div>
              <a className="red-cta" href={UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })}>
                JOIN MEALPAL TODAY
              </a>
            </div>
          </div>
          <div className="bottom-join-mealpal-button">
            <a className="red-cta" href={UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })}>
              JOIN MEALPAL TODAY
            </a>
          </div>
          <div className="healthy-meals">
            <div className="title">
              Introducing <span className="underline-brush-healthy-meals">Healthy Meals!</span>
            </div>
            <div className="grid-item grid-item-image images">
              <img src={HealthyMeals} />
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-image">
              <div>
                <img src={Sweetgreen} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={SweetgreenText} />
              </div>
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={FieldtripText} />
              </div>
            </div>
            <div className="grid-item grid-item-image">
              <div>
                <img src={Fieldtrip} />
              </div>
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-image">
              <div>
                <img src={Beatnic} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={BeatnicText} />
              </div>
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={AvocaderiaText} />
              </div>
            </div>
            <div className="grid-item grid-item-image">
              <div>
                <img src={Avocaderia} />
              </div>
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-image">
              <div>
                <img src={Taim} />
              </div>
            </div>
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={TaimText} />
              </div>
            </div>
          </div>
          <div className="healthy-grid-container">
            <div className="grid-item grid-item-text" style={{ maxWidth: '430px' }}>
              <div>
                <img src={JuicePressText} />
              </div>
            </div>
            <div className="grid-item grid-item-image">
              <div>
                <img src={JuicePress} />
              </div>
            </div>
          </div>
          <div className="kelsey-testimonial">
            <div className="title title-offers">
              FROM THE <span className="underline-brush-experts">EXPERTS</span>
              <br />
              THEMSELVES!
            </div>
            <div className="testimonial-container">
              <div className="testimonial-picture"></div>
              <div className="testimonial-text">
                <div className="name">
                  <span className="name-underline">Kelsey Butler</span>
                </div>
                <div className="profession">Registered Dietitian Nutritionist</div>
                <div>M.S. Clinical Nutrition Sciences</div>
                <br />
                <p className="speach">
                  Kelsey is a nutrition expert specializing in research and dietary advice. She prioritizes fostering
                  healthy relationships with food and sharing information about enjoyable options for everyone!
                </p>
              </div>
            </div>
          </div>
          <div className="kelsey-join-mealpal-button">
            <a className="red-cta" href={UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })}>
              JOIN MEALPAL TODAY
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default FitnessHero
